import { useState, useEffect } from "react";
import { Uploader as SUUploader } from "@stayunique/stayunique-ui";
import { Field } from "react-final-form";
// import { useSnackbar } from "notistack";

//utils
import fetcher from "../../utils/fetcher";
import { useTranslation } from "react-i18next";

type UploaderType = {
  /**
   * Name of the field
   */
  name: string;
  /**
   * upload destination
   */
  destination: string;
  /**
   * upload type
   */
  uploadType: string;
};

const UploaderWrapper = ({ onChange, value, destination, uploadType }: any) => {
  const [values, setValues] = useState<string[]>(value || []);
  const [errorAfterSubmit, setErrorAfterSubmit] = useState<string>("");
  // const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    onChange(values);
  }, [values, onChange]);

  //   useEffect(() => {
  //     setValues(value);
  //   }, [value]);
  console.log(values);
  const change = (value: string) => {
    setErrorAfterSubmit("");
    // let extensionRegex = /^\.[a-z0-9]+$/i;
    let validExtensions = /.(png|jpg|gif)$/i;
    const extension = value.split(".").pop() || "";

    if (!validExtensions.test(`.${extension}`)) {
      // enqueueSnackbar(t("edit.wrongImageExt"), { variant: "error", preventDuplicate: true });
      // setErrorAfterSubmit(t("edit.wrongImageExt"));

      // const fileIndexToDelete = values.findIndex(fileName => fileName === value);
      // console.log(fileIndexToDelete);
      // if (fileIndexToDelete !== -1) deleteFile(fileIndexToDelete);

      return;
    }

    setValues((prev: string[]) => {
      const newValues = [...prev, value];

      return newValues;
    });
  };

  const createSignedURL = async (file: any): Promise<any> => {
    let validExtensions = /.(png|jpg|gif)$/i;
    const extension = file.name.split(".").pop() || "";

    if (!validExtensions.test(`.${extension}`)) {
      // enqueueSnackbar(t("edit.wrongImageExt"), { variant: "error", preventDuplicate: true });
      setErrorAfterSubmit(t("edit.wrongImageExt"));

      // const fileIndexToDelete = values.findIndex(fileName => fileName === value);
      // console.log(fileIndexToDelete);
      // if (fileIndexToDelete !== -1) deleteFile(fileIndexToDelete);

      return;
    }

    return await fetcher("upload", {
      method: "POST",
      body: JSON.stringify({ destination: destination, upload_type: uploadType, filename: file.name, contentType: file.type })
    });
  };

  const deleteFile = async (index: number) => {
    await fetcher("upload", {
      method: "DELETE",
      body: JSON.stringify({
        key: value[index].split(".com/").pop()
      })
    });

    let newValue = [...values];

    if (index !== -1) {
      newValue.splice(index, 1);
    }

    setValues(newValue);
  };

  return (
    <div>
      <SUUploader createSignedURL={createSignedURL} onChange={change} values={values} onDelete={deleteFile} />
      {!!errorAfterSubmit && <p style={{ color: "red" }}>{errorAfterSubmit}</p>}
    </div>
  );
};

/** Uploader component */
const Uploader = ({ name, destination, uploadType }: UploaderType) => {
  return (
    <Field name={name}>
      {({ input }) => (
        <div>
          {Array.isArray(input.value) && input.value.length > 0 ? <p>Files count: {input.value.length}</p> : null}
          <UploaderWrapper destination={destination} uploadType={uploadType} value={input.value} onChange={input.onChange} />
        </div>
      )}
    </Field>
  );
};

export default Uploader;
