import SupervisorAccount from "@material-ui/icons/SupervisorAccount";

const configuration = {
    appName: "ownerReportApp",
    appIcon: <SupervisorAccount />,
    primary: "#4f3cc9",
    secondary: "#4f3cc9"
};

export default configuration;
