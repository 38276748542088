import { merge } from "lodash";

export default async function fetcherBlob(url: string, options = {}) {
  const token = localStorage.getItem("token");

  const defaultOptions = {
    headers: { "Content-Type": "application/json", Authorization: token ? `Bearer ${token}` : "" }
  };

  const fetchOptions = merge(defaultOptions, options);

  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/${url}`, fetchOptions);

    const result = await res.blob();
    if (res.status !== 200) {
      throw new Error("API error");
    }

    return result;
  } catch (e) {
    console.log(e);
  }
}
