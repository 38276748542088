import React, { useEffect, useReducer } from "react";

// ** Utils
import fetcher from "../utils/fetcher";
import { getSession, isValidToken, setSession } from "../utils/jwt";

export type LoginType = {
  username: string;
  password: string;
};

export interface User {
  userId: string;
  userLogin: string;
  userName: string;
  userEmail: string;
  accessLevel: number;
  profileId: string;
}

interface AuthContextType {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user?: User | null;
  login: (user: LoginType) => Promise<void>;
  logout: (user?: LoginType) => Promise<void>;
}

interface State {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: User | null;
}

interface Action {
  type: "INITIALIZE" | "LOGIN" | "LOGOUT";
  payload: { user: User | null; isAuthenticated: boolean };
}

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state: State, action: Action): State => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state: State, action: Action): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null
  })
};

const reducer = (state: State, action: Action): State => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = React.createContext<AuthContextType>({} as AuthContextType);

const AuthProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const logout = async (user?: LoginType) => {
    try {
      await fetcher("logout", {
        method: "POST",
        body: JSON.stringify(user)
      });

      setSession(null);

      dispatch({
        type: "LOGOUT",
        payload: {
          user: null,
          isAuthenticated: false
        }
      });
    } catch (error) {
      throw error;
    }
  };

  const login = async (user: LoginType) => {
    try {
      const result = await fetcher("login", {
        method: "POST",
        body: JSON.stringify(user)
      });

      setSession(result.token);

      dispatch({
        type: "LOGIN",
        payload: {
          user: result.data,
          isAuthenticated: true
        }
      });
    } catch (error) {
      throw error;
    }
  };

  const checkLogin = async () => {
    try {
      const result = await fetcher("me");
      const token = getSession();

      if (isValidToken(token || "")) {
        dispatch({
          type: "INITIALIZE",
          payload: {
            user: result.data,
            isAuthenticated: true
          }
        });
      } else {
        dispatch({
          type: "INITIALIZE",
          payload: {
            user: null,
            isAuthenticated: false
          }
        });
      }
    } catch (e) {
      dispatch({
        type: "INITIALIZE",
        payload: {
          user: null,
          isAuthenticated: false
        }
      });
    }
  };

  useEffect(() => {
    checkLogin();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        logout,
        login
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
