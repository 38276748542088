import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import { Field } from "react-final-form";

type CheckboxType = {
    /**
     * Classname of the field
     */
    className?: string;
    /**
     * Name of the field
     */
    name: string;
    /**
     * Label of the field
     */
    label?: string;
    /**
     * options
     */
    options: any;
};

const useStyles = makeStyles({
    content: { marginTop: 10 },
    item: { marginTop: 10 }
});

const CheckboxMultiWrapper = ({ className, onChange, value, label, options }: any) => {
    const classes = useStyles();

    const change = (val: any) => {
        let values = [...value];

        const index = values.findIndex(x => x === val);

        if (index === -1) {
            values.push(val);
        } else {
            values.splice(index, 1);
        }

        onChange(values);
    };

    const getCheckboxVal = (val: any) => {
        return value ? value.includes(val) : false;
    };

    return (
        <div className={className}>
            <InputLabel>{label}</InputLabel>
            <div className={classes.content}>
                {options.map((option: any, index: any) => {
                    return (
                        <div className={classes.item} key={index}>
                            <Typography>{option.highlight ? <b>{option.label}</b> : option.label}</Typography>
                            {option.children.map((item: any) => {
                                return <FormControlLabel key={item.value} control={<Checkbox checked={getCheckboxVal(item.value)} onChange={() => change(item.value)} />} label={item.label} />;
                            })}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

/** checkbox multi component */
const CheckboxMulti = ({ className, name, options }: CheckboxType) => {
    return <Field name={name}>{({ input }) => <CheckboxMultiWrapper className={className} options={options} value={input.value} onChange={input.onChange} />}</Field>;
};

export default CheckboxMulti;
