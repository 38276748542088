import { useState, useEffect } from "react";
import { Typography, IconButton, Box, Container, Paper } from "@mui/material";

// ** Icons
import AddIcon from "@material-ui/icons/Add";
import { Loader } from "@stayunique/stayunique-ui";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Select } from "@stayunique/stayunique-ui";
import { useSnackbar } from "notistack";

//utils
import fetcher from "../utils/fetcher";

//components
import RoomPicker from "../components/RoomPicker";

export const New = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [rooms, setRooms] = useState([]);
  const [propertiesList, setPropertiesList] = useState([]);
  const [currentProperty, setCurrentProperty] = useState<any>(null);
  const [values, setValues] = useState([]);
  const [needsRoomSelection, setNeedsRoomSelection] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);

  const onChange = (values: any) => {
    setValues(values);
  };

  const onSubmit = async () => {
    setIsDisabled(true);

    const result = await fetcher("ownerreport/new", {
      method: "POST",
      body: JSON.stringify({ rooms: values, propertyId: currentProperty })
    });

    setIsDisabled(false);

    const { visitId } = result.data;
    if (visitId) {
      navigate(`edit/${visitId}`);
    }
  };

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);

      try {
        const rooms = await fetcher("ownerreport/rooms");
        setRooms(rooms.data);

        const properties = await fetcher("properties");
        setPropertiesList(properties.data);
      } catch (e) {
        enqueueSnackbar(t("misc.error"), { variant: "error", preventDuplicate: true });
      }

      setIsLoading(false);
    }

    fetchData();
  }, [enqueueSnackbar, t]);

  useEffect(() => {
    async function fetchRooms() {
      if (currentProperty) {
        const result = await fetcher(`ownerreport/list/${currentProperty}`);

        try {
          if (result.data.length === 0) {
            setNeedsRoomSelection(true);
          } else {
            setNeedsRoomSelection(false);
          }
        } catch (e) {
          enqueueSnackbar(t("misc.error"), { variant: "error", preventDuplicate: true });
        }
      }
    }

    fetchRooms();
  }, [currentProperty, enqueueSnackbar, t]);

  const handleSelect = async (val: any) => {
    setCurrentProperty(val);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container maxWidth="xl" sx={{ pt: 10 }}>
      <Box component={Paper} sx={{ p: 2 }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6" color="inherit">
            {t("new.title")}
          </Typography>
          <IconButton onClick={onSubmit} disabled={(needsRoomSelection && values.length === 0) || !currentProperty || isDisabled} color="inherit" aria-label={t("misc.add")}>
            <AddIcon />
          </IconButton>
        </Box>
        <Select
          isClearable
          options={propertiesList.map((option: any) => ({
            value: option.propertyId,
            label: option.propertyName
          }))}
          onChange={handleSelect}
          value={currentProperty}
        />
        {rooms.length > 0 && needsRoomSelection && (
          <div>
            <Typography variant="h5">{t("new.selectRooms")}</Typography>
            <RoomPicker onChange={onChange} rooms={rooms} />
          </div>
        )}
      </Box>
    </Container>
  );
};
