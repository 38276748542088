import { Navigate } from "react-router-dom";

// ** Layouts
import { MainLayout } from "../layout/MainLayout";

// ** Guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";

// ** Pages
import { Login, Main, Edit, New } from "../pages";
import { GuestLayout } from "../layout/GuestLayout";

export const Routes = () => {
  return [
    {
      path: "login",
      element: (
        <GuestGuard>
          <GuestLayout>
            <Login />
          </GuestLayout>
        </GuestGuard>
      )
    },
    {
      path: "/",
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          index: true,
          element: <Main />
        },
        {
          path: "edit/:id",
          element: <Edit />
        },
        {
          path: "new",
          element: <New />
        }
      ]
    },
    { path: "*", element: <Navigate to="/" replace /> }
  ];
};
