import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Typography, IconButton, Button, Paper, InputLabel, Tab, Tabs, Box } from "@mui/material";

// ** Icons
import DeleteIcon from "@material-ui/icons/Delete";
import CopyIcon from "@material-ui/icons/FileCopy";
import Send from "@material-ui/icons/Send";
import Save from "@material-ui/icons/Save";
import Download from "@material-ui/icons/CloudDownload";

// ** Third party modules
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { saveAs } from "file-saver";

//utils
import fetcher from "../utils/fetcher";
import fetcherBlob from "../utils/fetcherBlob";

//fields
import TabPanel from "../components/fields/TabPanel";
import Uploader from "../components/fields/Uploader";
import CheckboxMulti from "../components/fields/CheckboxMulti";
import TextField from "../components/fields/TextField";
import { Loader } from "@stayunique/stayunique-ui";

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

export const Edit = () => {
  // ** Hooks
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams<{ id: string }>();

  // ** States
  const [visit, setVisit] = useState({ propertyName: undefined, sections: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);

      try {
        const visit = await fetcher(`/ownerreport/visit/${id}`);

        setVisit(visit.data);
        setActiveStep(0);
      } catch (e) {
        enqueueSnackbar(t("misc.error"), { variant: "error", preventDuplicate: true });
      }

      setIsLoading(false);
    }
    fetchData();
  }, [id, t, enqueueSnackbar]);

  let submit = async () => {};

  const handleDownload = async () => {
    try {
      await submit();

      const result: any = await fetcherBlob(`ownerreport/zipAssets/${id}`);

      const filename = "download.zip";
      saveAs(result, filename);
    } catch (e) {
      console.log(e);
      enqueueSnackbar(t("misc.error"), { variant: "error", preventDuplicate: true });
    }
  };

  const save = async (values: any) => {
    setIsDisabled(true);

    try {
      await fetcher(`ownerreport/update/${id}`, {
        method: "POST",
        body: JSON.stringify(values)
      });
    } catch (e) {
      throw e;
    }

    setIsDisabled(false);
  };

  const handleSave = async () => {
    try {
      await submit();
      enqueueSnackbar(t("edit.saveSuccess"), { variant: "success", preventDuplicate: true });
    } catch (e) {
      enqueueSnackbar(t("misc.error"), { variant: "error", preventDuplicate: true });
    }
  };

  const handleFinalise = async () => {
    try {
      await submit();

      setIsDisabled(true);

      await fetcher(`ownerreport/finalise/${id}`, { method: "POST" });

      setIsDisabled(false);

      enqueueSnackbar(t("edit.finaliseSuccess"), { variant: "success", preventDuplicate: true });
    } catch (e) {
      enqueueSnackbar(t("misc.error"), { variant: "error", preventDuplicate: true });
    }
  };

  const changeTab = (event: any, newValue: any) => {
    setActiveStep(newValue);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Paper sx={{ mt: 10 }}>
      <Box sx={{ mt: 2, px: 2, display: "flex", alignItems: "center" }}>
        <Typography variant="h6" color="inherit">
          {t("edit.title", {
            property: visit.propertyName
          })}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton onClick={handleDownload} color="inherit" aria-label={t("edit.download")}>
          <Download />
        </IconButton>
        <IconButton onClick={handleSave} disabled={isDisabled} color="inherit" aria-label={t("edit.save")}>
          <Save />
        </IconButton>
        <IconButton onClick={handleFinalise} disabled={isDisabled} color="inherit" aria-label={t("edit.send")}>
          <Send />
        </IconButton>
      </Box>

      <Tabs variant="scrollable" scrollButtons value={activeStep} onChange={changeTab} aria-label="simple tabs example">
        {visit.sections.map((section: any, index: any) => {
          return <Tab key={section.sectionId} label={section.sectionName} {...a11yProps(index)} style={{ padding: "1em" }} />;
        })}
      </Tabs>

      <Form
        onSubmit={save}
        mutators={{
          ...arrayMutators
        }}
        initialValues={visit}
        render={({ handleSubmit }: any) => {
          submit = handleSubmit;

          return (
            <form onSubmit={handleSubmit} style={{ padding: 10 }} id="visitForm">
              <FieldArray name="sections">
                {({ fields }) =>
                  fields.map((section: any, parentIndex: any) => (
                    <TabPanel value={activeStep} key={parentIndex} index={parentIndex}>
                      <FieldArray name={`${section}.items`}>
                        {({ fields }) => (
                          <div key={section.sectionId}>
                            {fields.map((items: any, index: any) => (
                              <div key={index} style={{ marginBottom: 20 }}>
                                <div>
                                  <Typography variant="h6">{t("edit.item")}</Typography>

                                  <div>
                                    <IconButton
                                      onClick={() => {
                                        fields.push(fields.value[index]);
                                      }}
                                    >
                                      <CopyIcon />
                                    </IconButton>

                                    <IconButton onClick={() => fields.remove(index)}>
                                      <DeleteIcon />
                                    </IconButton>
                                  </div>
                                </div>

                                <div>
                                  <CheckboxMulti
                                    name={`${items}.itemResponsable`}
                                    label={t("edit.responsable")}
                                    options={[
                                      {
                                        label: t("edit.responsableOwner"),
                                        highlight: true,
                                        children: [
                                          {
                                            value: 1,
                                            label: t("edit.responsableOwnerKeys")
                                          },
                                          {
                                            value: 2,
                                            label: t("edit.responsableOwnerMissingDocuments")
                                          },
                                          {
                                            value: 3,
                                            label: t("edit.responsableOwnerDoubts")
                                          },
                                          {
                                            value: 4,
                                            label: t("edit.responsableOwnerDisposal")
                                          },
                                          {
                                            value: 5,
                                            label: t("edit.responsableOwnerPurchases")
                                          },
                                          {
                                            value: 6,
                                            label: t("edit.responsableOwnerPainting")
                                          },
                                          {
                                            value: 7,
                                            label: t("edit.responsableOwnerIssues")
                                          },

                                          {
                                            value: 8,
                                            label: t("edit.responsableOwnerCondition")
                                          }
                                        ]
                                      },
                                      {
                                        label: t("edit.responsableMaintenance"),
                                        highlight: true,
                                        children: [
                                          {
                                            value: 9,
                                            label: t("edit.responsableMaintenanceIssues")
                                          }/* ,
                                          {
                                            value: 10,
                                            label: t("edit.responsableMaintenanceInfo")
                                          } */
                                        ]
                                      },

                                      {
                                        label: t("edit.responsableCleaning"),
                                        highlight: true,
                                        children: [
                                          {
                                            value: 11,
                                            label: t("edit.responsableCleaningIssues")
                                          },
                                          {
                                            value: 12,
                                            label: t("edit.responsableCleaningInfo")
                                          }
                                        ]
                                      },
                                      {
                                        label: t("edit.responsableStay"),
                                        children: [
                                          {
                                            value: 13,
                                            label: t("edit.responsableStayQualityControl")
                                          },
                                          {
                                            value: 14,
                                            label: t("edit.responsableStayMarketing")
                                          },
                                          {
                                            value: 15,
                                            label: t("edit.responsableStayInfoGuests")
                                          },
                                          {
                                            value: 16,
                                            label: t("edit.responsableStayInfoAgents")
                                          }
                                        ]
                                      }
                                    ]}
                                  />
                                </div>
                                <div>
                                  <InputLabel>{t("edit.content")}</InputLabel>

                                  <TextField name={`${items}.itemContent`} label={t("edit.content")} multiline fullWidth />
                                </div>

                                <div>
                                  <InputLabel>{t("edit.images")}</InputLabel>

                                  <Uploader name={`${items}.itemImages`} destination="owner_reports" uploadType="owner_report" />
                                </div>
                              </div>
                            ))}
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                fields.push({
                                  itemResponsable: [],
                                  itemContent: "",
                                  itemImages: []
                                })
                              }
                            >
                              {t("edit.addItem")}
                            </Button>
                          </div>
                        )}
                      </FieldArray>
                    </TabPanel>
                  ))
                }
              </FieldArray>
            </form>
          );
        }}
      />
    </Paper>
  );
};
