import React, { useState, useEffect } from "react";
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { useTranslation } from "react-i18next";

const Rooms = ({ rooms, onChange }: any) => {
    const { t } = useTranslation();

    const [roomsState] = useState(rooms);
    const [roomsAvailable, setRoomsAvailable] = useState(rooms);
    const [roomsSelected, setRoomsSelected] = useState<any>([]);

    const addRoom = (id: any) => {
        setRoomsAvailable((prevState: any) => prevState.filter((item: any) => item.roomId !== id));
        let newRoom = roomsState.find((item: any) => item.roomId === id);
        setRoomsSelected((prevState: any) => [...prevState, newRoom]);
    };

    const removeRoom = (id: any) => {
        setRoomsSelected((prevState: any) => prevState.filter((item: any) => item.roomId !== id));
        let newRoom = roomsState.find((item: any) => item.roomId === id);

        setRoomsAvailable((prevState: any) => [...prevState, newRoom].sort((a, b) => (a.roomId > b.roomId ? 1 : -1)));
    };

    const changeRoomName = (name: any, id: any) => {
        const index = roomsSelected.findIndex((item: any) => item.roomId === id);

        let newRooms: any = [...roomsSelected];

        newRooms[index].roomName = name;

        setRoomsSelected(newRooms);
    };

    useEffect(() => {
        if (roomsSelected.length > 0) {
            onChange(roomsSelected);
        }
    }, [roomsSelected, onChange]);

    return (
        <Grid container>
            <Grid item md={6}>
                <Typography variant="h6">{t("new.available")}</Typography>
                <List>
                    {roomsAvailable.map((room: any) => {
                        return (
                            <ListItem key={room.roomId}>
                                <ListItemText primary={room.roomName} />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label={t("misc.add")} onClick={() => addRoom(room.roomId)}>
                                        <AddIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        );
                    })}
                </List>
            </Grid>

            <Grid item md={6}>
                <Typography variant="h6">{t("new.selected")}</Typography>
                <List>
                    {roomsSelected.map((room: any) => {
                        return (
                            <ListItem key={room.roomId}>
                                <TextField label={room.baseRoomName} value={room.roomName} onChange={event => changeRoomName(event.target.value, room.roomId)} fullWidth></TextField>
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label={t("misc.delete")} onClick={() => removeRoom(room.roomId)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        );
                    })}
                </List>
            </Grid>
        </Grid>
    );
};

export default Rooms;
