import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, TableContainer, Table, TableRow, TableCell, TableBody, Typography, Paper, Toolbar, TextField, MenuItem, Box, Divider, Fab, Grid } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";

// ** Hooks
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

// ** Components
import { Loader } from "@stayunique/stayunique-ui";

// ** Utils
import fetcher from "../utils/fetcher";
import dayjs from "dayjs";

type FilterType = {
  [key: number]: any;
  viewBuilding: string;
  viewSearch: string;
};

type Visit = {
  visitId: string;
  propertyId: string;
  visitDate: string;
  usrPK: string;
  visitJSON: string;
  propertyName: string;
};

type Building = {
  buildingId: string;
  buildingName: string;
};

export const Main = () => {
  // ** Hooks
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // ** States
  const [loading, setLoading] = useState(true);
  const [visits, setVisits] = useState<Visit[]>([]);
  const [visitsToFilter, setVisitsToFilter] = useState<Visit[]>([]);
  const [buildings, setBuildings] = useState<Building[]>([]);
  const [filters, setFilters] = useState<FilterType>({
    viewBuilding: "0",
    viewSearch: ""
  });

  const fetchData = async () => {
    if (filters.viewSearch.length === 0) {
      setLoading(true);

      try {
        const visits = await fetcher("ownerreport/list", { method: "POST", body: JSON.stringify(filters) });
        setVisits(visits.data);
        setVisitsToFilter(visits.data);

        const buildings = await fetcher("buildings");
        setBuildings(buildings.data);
      } catch (e) {
        enqueueSnackbar(t("misc.error"), { variant: "error", preventDuplicate: true });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleChangeFilter = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setFilters(prev => ({ ...prev, [target.name]: target.value }));

    if (target.name === "viewSearch") {
      const newVisits = visitsToFilter.filter((item: any) => item.propertyName.toLowerCase().search(target.value.toLowerCase()) !== -1);
      setVisits(newVisits);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.viewBuilding]);

  return loading ? (
    <Loader />
  ) : (
    <Container sx={{ pt: 10 }}>
      <Paper sx={{ p: 0 }}>
        {/* Filters */}
        <Toolbar sx={{ py: 2, display: { xs: "none", sm: "flex" } }}>
          <Typography>
            {t("list.results_found")}: {visits.length}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <TextField label={t("list.search")} name="viewSearch" value={filters.viewSearch} onChange={handleChangeFilter} sx={{ width: 220, mr: 1 }} />
          <TextField select label={t("list.building")} name="viewBuilding" value={filters.viewBuilding} onChange={handleChangeFilter} sx={{ minWidth: 220 }}>
            <MenuItem value="0">{t("filters.buildings.all")}</MenuItem>
            {buildings.map(building => (
              <MenuItem key={building.buildingId} value={building.buildingId}>
                {building.buildingName}
              </MenuItem>
            ))}
          </TextField>
        </Toolbar>

        <Grid container spacing={2} sx={{ display: { xs: "block", sm: "none" }, p: 2 }}>
          <Grid item xs={12}>
            <Typography>
              {t("list.results_found")}: {visits.length}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField label={t("list.search")} name="viewSearch" value={filters.viewSearch} onChange={handleChangeFilter} fullWidth />
          </Grid>
          <Grid item xs={12}>
            <TextField select label={t("list.building")} name="viewBuilding" value={filters.viewBuilding} onChange={handleChangeFilter} fullWidth>
              <MenuItem value="0">{t("filters.buildings.all")}</MenuItem>
              {buildings.map(building => (
                <MenuItem key={building.buildingId} value={building.buildingId}>
                  {building.buildingName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Divider />

        {/* Data */}
        <TableContainer sx={{ maxHeight: 740 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableBody>
              {visits.map(visit => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={visit.visitId} sx={{ width: "100%", borderRadius: 0, p: 0 }} onClick={() => navigate(`/edit/${visit.visitId}`)}>
                    <TableCell sx={{ p: 1, width: "100%", display: "block" }}>
                      <Typography variant="subtitle1">{visit.propertyName}</Typography>
                      <Typography variant="subtitle2">{dayjs(visit.visitDate).format("DD/MM/YY HH:mm")}</Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Fab
        color="primary"
        aria-label="add"
        onClick={() => navigate("/new")}
        sx={{
          position: "absolute",
          bottom: 16,
          right: 16
        }}
      >
        <AddIcon />
      </Fab>
    </Container>
  );
};
