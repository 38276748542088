import React from "react";
import { BrowserRouter } from "react-router-dom";

// ** Providers
import { CssBaseline } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AuthProvider } from "./context/AuthContext";
import { ConfigProvider } from "./context/ConfigContext";

// ** Router
import { Router } from "./routes";

import "./i18n/config";
import "./App.css";

function App() {
    return (
        <ConfigProvider>
            <AuthProvider>
                <CssBaseline />
                <SnackbarProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <BrowserRouter>
                            <Router />
                        </BrowserRouter>
                    </LocalizationProvider>
                </SnackbarProvider>
            </AuthProvider>
        </ConfigProvider>
    );
}

export default App;

// import React from "react";
// import { Route, Switch, BrowserRouter as Router, Redirect } from "react-router-dom";
// import "./App.css";
// import CssBaseline from "@material-ui/core/CssBaseline";
// import { SnackbarProvider } from "notistack";

// //components
// import Login from "./components/Login";
// import Main from "./components/Main";
// import Edit from "./components/Edit";
// import New from "./components/New";
// import Settings from "./components/Settings";
// import PrivateRoute from "./components/PrivateRoute";

// //contextd
// import { AuthProvider } from "./context/AuthContext";
// import { ConfigProvider } from "./context/ConfigContext";

// //config
// import "./i18n/config";

// function App() {
//     return (
//         <ConfigProvider>
//             <CssBaseline />
//             <SnackbarProvider>
//                 <AuthProvider>
//                     <Router>
//                         <Switch>
//                             <PrivateRoute exact path="/" component={Main} />
//                             <Route exact path="/login" component={Login} />
//                             <PrivateRoute exact path="/settings" component={Settings} />
//                             <PrivateRoute path="/edit/:id" component={Edit} />
//                             <PrivateRoute exact path="/new" component={New} />
//                             <Redirect to="/" />
//                         </Switch>
//                     </Router>
//                 </AuthProvider>
//             </SnackbarProvider>
//         </ConfigProvider>
//     );
// }

// export default App;
