import { Outlet } from "react-router-dom";

// ** Components
import { AppBarLayout } from "./AppBarLayout";

export const MainLayout = () => {
  return (
    <div style={{ backgroundColor: "#f5f5f5", minHeight: "100vh", padding: 0 }}>
      <AppBarLayout />
      <Outlet />
    </div>
  );
};
