import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";

// ** Hooks
import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";

interface Props {
  open: boolean;
  onClose: () => void;
}

export const CloseSessionDialog = ({ open, onClose }: Props) => {
  // ** Hooks
  const { logout } = useAuth();
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t("nav.close_session")}</DialogTitle>

      <DialogActions>
        <Button onClick={onClose}>{t("misc.cancel")}</Button>
        <Button color="error" onClick={() => logout()}>
          {t("misc.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
