import { TextField as SUTextField } from "@stayunique/stayunique-ui";
import { Field } from "react-final-form";

type TextFieldType = {
    /**
     * Class name to apply
     */
    className?: string;
    /**
     * Label of the field
     */
    label?: string;
    /**
     * Placeholder of the field
     */
    placeholder?: string;
    /**
     * Name of the field
     */
    name: string;
    /**
     * Type of the field
     */
    type?: "text" | "number";
    /**
     * Is field full width
     */
    fullWidth?: boolean;
    /**
     * Variant of the field
     */
    variant?: "standard" | "outlined" | "filled";
    /**
     * Is field multi  line
     */
    multiline?: boolean;
};

/** TextField component */
const TextField = ({ className, name, label, placeholder, type, fullWidth, variant, multiline }: TextFieldType) => {
    return (
        <Field name={name} type={type}>
            {({ input }) => (
                <SUTextField
                    className={className}
                    label={label}
                    placeholder={placeholder}
                    onChange={input.onChange}
                    value={input.value}
                    type={input.type}
                    fullWidth={fullWidth}
                    variant={variant}
                    multiline={multiline}
                />
            )}
        </Field>
    );
};

export default TextField;
