import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AppBar, Box, Toolbar, IconButton, Typography, MenuItem, Menu } from "@mui/material";

// ** Hooks
import { useTranslation } from "react-i18next";
import useAuth from "../hooks/useAuth";

// ** Icons
import ArrowBack from "@mui/icons-material/ArrowBack";
import Language from "@mui/icons-material/Language";
import ExitToApp from "@mui/icons-material/ExitToApp";
import MoreIcon from "@mui/icons-material/MoreVert";

// ** Components
// import { Search } from "../components/AppBar/SearchInput";
import { CloseSessionDialog } from "../components/AppBar/CloseSessionDialog";

export const AppBarLayout = () => {
  // ** States
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);

  // ** Hooks
  const { t, i18n } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleLanguageMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    setOpenDialog(true);
  };

  const changeLang = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang);
  };

  const menuId = "language-popup";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => changeLang("es")} {...(i18n.language === "es" && { sx: { fontWeight: "bold" } })}>
        {t("languages.spanish")}
      </MenuItem>
      <MenuItem onClick={() => changeLang("en")} {...(i18n.language === "en" && { sx: { fontWeight: "bold" } })}>
        {t("languages.english")}
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleLanguageMenuOpen}>
        {/* Language */}
        <IconButton size="large" aria-label="change language app" aria-controls={menuId} aria-haspopup="true" color="inherit">
          <Language />
        </IconButton>
        <p>{t("languages.language")}</p>
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <IconButton size="large" aria-label="exit to app" color="inherit">
          <ExitToApp />
        </IconButton>
        <p>{t("nav.logout")}</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed">
        <Toolbar>
          {/* Handle Back */}
          {pathname !== "/" && (
            <IconButton size="large" color="inherit" onClick={() => navigate(-1)} sx={{ mr: 1 }}>
              <ArrowBack />
            </IconButton>
          )}

          <Typography variant="h6" noWrap component="div" sx={{ display: "block" }}>
            Owner Report App
          </Typography>

          {/* Search field */}
          {/* <Search /> */}

          <Box sx={{ flexGrow: 1 }} />

          {/* Menus */}
          <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: { xs: "initial", md: "center" } }}>
            {/* User */}
            <Typography sx={{ mr: 1 }}>{user?.userName}</Typography>

            {/* Language */}
            <IconButton size="large" aria-label="change language app" aria-controls={menuId} aria-haspopup="true" color="inherit" onClick={handleLanguageMenuOpen}>
              <Language />
            </IconButton>

            {/* Logout */}
            <IconButton size="large" edge="end" onClick={handleLogout} color="inherit">
              <ExitToApp />
            </IconButton>
          </Box>

          {/* Mobile Menus */}
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton size="large" aria-label="show more" aria-controls={mobileMenuId} aria-haspopup="true" onClick={handleMobileMenuOpen} color="inherit">
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      {renderMobileMenu}
      {renderMenu}

      <CloseSessionDialog open={openDialog} onClose={() => setOpenDialog(false)} />
    </Box>
  );
};
