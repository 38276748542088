import React from "react";

// ** Hooks
import useAuth from "../hooks/useAuth";

import { Login } from "../pages";

export default function AuthGuard({ children }: { children: JSX.Element }) {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Login />;
  }

  return <>{children}</>;
}
