import { useNavigate } from "react-router-dom";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";

// ** Hooks
import useAuth from "../hooks/useAuth";

// ** Interfaces && Types
import { LoginType } from "../context/AuthContext";

const isDevelopment = process.env.NODE_ENV === "development" && process.env.REACT_APP_DEFAULT_USER && process.env.REACT_APP_DEFAULT_PASSWORD;

export const Login = () => {
  // ** Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { login } = useAuth();
  const formik = useFormik({
    initialValues: {
      username: isDevelopment ? process.env.REACT_APP_DEFAULT_USER! : "",
      password: isDevelopment ? process.env.REACT_APP_DEFAULT_PASSWORD! : ""
    },
    onSubmit: async (values: LoginType) => {
      try {
        await login(values);
        navigate("/");
      } catch (error: any) {
        enqueueSnackbar(error.message, {
          variant: "error",
          preventDuplicate: true
        });
      }
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().required(t("forms.required")),
      password: Yup.string().required(t("forms.required"))
    })
  });

  return (
    <Box sx={{ height: "100vh", display: "flex", alignItems: "center", justifyContent: "center", mt: -30 }} component="form" onSubmit={formik.handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4" textAlign="center" my={1}>
            Owner Report App
          </Typography>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <TextField
            id="username"
            type="text"
            label={t("login.username")}
            name="username"
            onChange={formik.handleChange}
            value={formik.values.username}
            sx={{ width: { xs: 325, sm: 411 } }}
            disabled={formik.isSubmitting}
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <TextField
            id="password"
            type="password"
            label={t("login.password")}
            name="password"
            onChange={formik.handleChange}
            value={formik.values.password}
            sx={{ width: { xs: 325, sm: 411 } }}
            disabled={formik.isSubmitting}
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <LoadingButton type="submit" variant="contained" color="primary" sx={{ width: { xs: 325, sm: 411 } }} loading={formik.isSubmitting} disabled={formik.isSubmitting}>
            {t("login.login")}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};
