import { Navigate } from "react-router-dom";

// ** Hooks
import useAuth from "../hooks/useAuth";

export default function GuestGuard({ children }: { children: JSX.Element }) {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
}
