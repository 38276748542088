import jwtDecode from "jwt-decode";

const isValidToken = (accessToken: string | null): boolean => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem("token", accessToken);
  } else {
    localStorage.removeItem("token");
  }
};

const getSession = (): string | null => {
  return localStorage.getItem("token");
};

export { isValidToken, setSession, getSession };
